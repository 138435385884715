import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../../components/Layout';
import { HTag } from '../../components/HTag';
import Img from 'gatsby-image';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { styleVariables } from '../../styles/Variables';

interface Props {
  data: any;
  pageContext: any;
}

const StyledArticle = styled.article`
  max-width: 600px;
  margin: 0 auto;
`;
const StyledImage = styled(Img)`
  max-width: 600px;
  border-radius: ${styleVariables.borderRadius};
  margin: 40px 0;
`;
const UpdatePost = ({ data, pageContext }: Props) => {
  const {
    markdownRemark: { frontmatter, html },
  } = data;

  return (
    <Layout hasHero={false}>
      <StyledArticle>
        <HTag style={{ marginBottom: '0px' }}> {frontmatter.title}</HTag>
        <time>
          {dayjs().subtract(pageContext.index, 'd').format('MM/DD/YYYY')}
        </time>

        <StyledImage fluid={frontmatter.featuredImage.childImageSharp.fluid} />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </StyledArticle>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($pagePath: String!) {
    markdownRemark(frontmatter: { path: { eq: $pagePath } }) {
      html
      frontmatter {
        date
        title
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default UpdatePost;
